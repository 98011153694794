<template>
  <div class="repair-remind-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <!-- 头部s -->
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">维保提醒</div>
        <div class="right">
          <button @click="toDevice">
            <img src="@/assets/images/home/return.png" alt="" />
            <span class="f14 c6">返回</span>
          </button>
        </div>
      </el-row>
      <div class="line-long"></div>
      <!-- 头部e -->
      <!-- 设备信息s -->
      <div class="msg-box mt24 b">
        <p class="line1">
          <span class="id f20">{{ deviceDetail.deviceNumber }}</span>
          <span class="num f16"
            >No.{{
              deviceDetail.systemNumber ? deviceDetail.systemNumber : "-"
            }}</span
          >
        </p>
        <p class="line2">
          <span class="f16 c3"
            >设备类型：{{
              deviceDetail.deviceTypeParentName
                ? deviceDetail.deviceTypeParentName +
                  "-" +
                  deviceDetail.deviceTypeName
                : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备品牌：{{
              deviceDetail.brandName ? deviceDetail.brandName : "-"
            }}</span
          >
          <span class="f16 c3"
            >出厂日期：{{
              deviceDetail.productionDate ? deviceDetail.productionDate : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备型号：{{
              deviceDetail.deviceModel ? deviceDetail.deviceModel : "-"
            }}</span
          >
          <span class="f16 c3"
            >控制系统：{{
              deviceDetail.controlName ? deviceDetail.controlName : "-"
            }}</span
          >
        </p>
      </div>
      <!-- 设备信息e -->
      <!-- 添加设备资料 s -->
      <div class="mt24 add">
        <button>
          <img src="@/assets/images/home/add.png" alt="" />
          <span class="cf f14"
            ><router-link :to="{ name: 'add-present-time', query: { id } }"
              >添加预设时间</router-link
            ></span
          >
        </button>
      </div>
      <!-- 添加设备资料 e -->
      <!-- 表格展示 start -->
      <el-row class="table-box mt20">
        <el-table
          :data="tableData"
          stripe
          :header-cell-style="{ background: '#F8F9FB', height: '56px' }"
          style="width: 100%"
          header-cell-class-name="table-header"
        >
          <el-table-column prop="num" label="序号" width="67">
             <template slot-scope="{ row }">{{ row.num || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="维保名称"
            width="146"
          >
             <template slot-scope="{ row }">{{ row.name || "-" }}</template>
          </el-table-column>
          <el-table-column
            prop="contant"
            align="center"
            label="维保内容"
            width="428"
          ></el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="time"
            label="维保时间"
            width="159"
          >
            <template slot-scope="{ row }">{{
              row.time || "-"
            }}</template></el-table-column
          >
          <el-table-column
            class-name="blue"
            align="center"
            prop="remind"
            label="维保提醒"
            width="144"
          >
            <template slot-scope="scope">
              <span
                class="edit"
                :class="{ green: scope.row.remind == '接受' }"
                v-if="scope.row.whetherPush == 1"
                style="color: #4ca336"
                >接受</span
              >
              <span
                class="edit"
                :class="{ red: scope.row.remind == '不接受' }"
                v-if="scope.row.whetherPush == 0"
                style="color: #ed1b23"
                >不接受</span
              >
            </template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            prop="remind"
            align="center"
            label="操作"
            width="190"
          >
            <template slot-scope="scope">
              <!-- 编辑 -->
              <span
                @click="handleEdit(scope.$index, scope.row)"
                class="f14 see p b"
              >
                <span class="edit">编辑</span>
              </span>
              <span class="line"></span>
              <!-- 删除 -->
              <span
                @click="handleDel(scope.$index, scope.row)"
                class="f14 see p b"
              >
                <span class="del">删除</span>
              </span>
            </template>
            <!-- <ul class="handle">
              <li>设备资料</li>
              <li class="line"></li>
              <li>编辑</li>
            </ul> -->
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 表格展示 end -->
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
    </Layout>
    <Footer></Footer>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangRepairrecord",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      id: "",
      // 表格相关数据
      tableData: [], //表格数据
      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,

      deviceDetail: [], //设备详情
    };
  },

  mounted() {},

  created() {
    this.id = this.$route.query.id;
    // 维保记录-分页列表查询
    this.getRepairRemindList();
    // 通过id查询设备信息
    this.getDeviceRes();
  },

  methods: {
    // 通过id查询设备信息
    getDeviceRes() {
      let paramData = {
        id: this.id,
      };
      this.$API
        .getDeviceRes(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.deviceDetail = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 维保记录-分页列表查询
    getRepairRemindList() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        deviceId: this.id,
      };
      this.$API
        .getRepairRemindList(paramData)
        .then((res) => {
          if (res.code == 1) {
            //   num: "1",
            // name: "维保名称A",
            // contant: "维修保护1号机床并将普通机床进行整理调整后提交验收",
            // time: "2021/10/10",
            // remind: "不接受",
            this.tableData = [];
            this.listTotal = Number(res.data.total);
            res.data.records.forEach((item, index) => {
              this.tableData.push({
                num: (this.page - 1) * this.pageSize + index + 1,
                name: item.maintenanceName,
                contant: item.maintenanceContent,
                time: item.remindTime,
                whetherPush: item.whetherPush,
                id: item.id,
              });
              console.log(this.tableData);
            });
            // 后台返回的，要保持原样
            // 想前端拼参数，可以加个标识
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑
    handleEdit(index, item) {
      // item.id  此项内容对应id
      this.$router.push(`/edit-device-remind?eid=${item.id}&id=${this.id}`);
    },
    // 删除
    handleDel(index, item) {
      // item.id  此项内容对应id
      // console.log(item);
      let paramData = {
        id: item.id,
      };
      this.$API
        .delRepairRemindId(paramData)
        .then((res) => {
          if (res.code == 1) {
            // this.$alert("这是一段内容", "标题名称", {
            //   confirmButtonText: "确定",
            //   callback: (action) => {
            //     this.$message({
            //       type: "info",
            //       message: `action: ${action}`,
            //     });
            //   },
            // });
            this.$alert("确定要删除吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              showCancelButton: true,
              callback: (action) => {
                // 确定  confirm  取消 cancel
                if (action == "confirm") {
                  this.page = 1;
                  // 维保记录-分页列表查询
                  this.getRepairRemindList();
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                }
              },
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.tableData = [];
      this.getRepairRemindList();
    },
    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 返回
    toDevice() {
      this.$router.push("/device");
    },
  },
};
</script>

<style lang="scss">
.repair-remind-contaner {
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .green {
    color: #0bc197 !important;
  }
  .red {
    color: #ed1b23 !important;
  }
  .edit {
    color: #1577fc;
  }
  .del {
    color: #666666;
  }
  .line {
    width: 0px;
    height: 13px;
    border: 1px solid #c6ccda;
    margin: 10px;
  }
  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   设备信息
  .msg-box {
    width: 1120px;
    min-height: 119px;
    background: #ffeff0;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #ed1b23;
    padding: 24px 20px 20px 20px;
    .line1 {
      padding-bottom: 17px;
      border-bottom: 1px solid #f4c8cb;
      .id {
        color: #ff4656;
        margin-right: 12px;
      }
      .num {
        color: #99989c;
      }
    }
    .line2 {
      margin-top: 16px;
      span {
        margin-right: 43px;
      }
    }
  }
  //   添加设备资料
  .add {
    button {
      width: 149px;
      height: 40px;
      background: #1577fc;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  // 表格
  .table-box {
    margin-bottom: 48px;
    .handle {
      height: 39px;
      ul {
        height: 39px;
        line-height: 39px;
        display: flex;
        align-items: center;
      }
      li {
        float: left;
        font-size: 14px;
        font-weight: 400;
        // color: #2a89ff;
        line-height: 22px;
      }
      .line {
        width: 0px;
        height: 13px;
        // border: 1px solid #c6ccda;
        margin: 12px 12px;
      }
    }
  }
}
</style>